


































import Vue, { PropType } from 'vue';

export interface WindowMenuItem {
  label: string;
  shortcut: string;
  action: () => void;
}

export default Vue.extend({
  props: {
    menuOpen: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array as PropType<WindowMenuItem[]>,
      default: (): WindowMenuItem[] => [],
    },

    dividersAfter: {
      type: [String, Number, Array] as PropType<string | number | (string | number)[]>,
      default: (): string | number | (string | number)[] => [],
    },
  },

  data() {
    return {
      localMenuOpen: this.menuOpen,
    };
  },

  computed: {
    dividerIndices(): number[] {
      const indexList = Array.isArray(this.dividersAfter) ? this.dividersAfter : [this.dividersAfter];
      return indexList.map(divIndex => typeof divIndex === 'string' ? parseInt(divIndex, 10) : divIndex);
    },
  },

  watch: {
    localMenuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal !== this.menuOpen) this.$emit("update:menuOpen", newVal);
    },

    menuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal !== this.localMenuOpen) this.localMenuOpen = newVal;
    },
  },

  methods: {
    onClick(): void {
      this.localMenuOpen = !this.localMenuOpen;
    },

    onBlur(): void {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.localMenuOpen) this.localMenuOpen = false;
        }, 100);
      });
    },

    onClickItem(item: WindowMenuItem): void {
      item.action();
      this.$nextTick(() => {
        this.localMenuOpen = false;
      });
    },
  },
});
