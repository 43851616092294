




















import Vue, { PropType } from 'vue';
import WordIcon from '@/components/ms_word/WordIcon.vue';
import { MsWordIconName } from '@/lib/ms_word/icons';

export default Vue.extend({
  components: {
    WordIcon,
  },

  props: {
    icon: {
      type: String as PropType<MsWordIconName>,
      required: true,
    },

    appended: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: "auto",
    },

    toggle: {
      type: Boolean,
      default: false,
    },

    pressed: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localPressed: this.pressed,
    };
  },

  watch: {
    localPressed(newVal: boolean, _oldVal: boolean): void {
      if (newVal !== this.pressed) this.$emit("update:pressed", newVal);
    },

    pressed(newVal: boolean, _oldVal: boolean): void {
      if (newVal !== this.localPressed) this.localPressed = newVal;
    },
  },

  methods: {
    // onMouseDown(): void {
    //   // if (this.disabled) return;
    //   // if (this.toggle) return;
    //   // this.localPressed = true;
    //   if (this.disabled) return;
    //   if (this.toggle) {
    //     this.localPressed = !this.localPressed;
    //   } else {
    //     this.localPressed = true;
    //   }
    // },

    // onMouseUp(): void {
    //   if (this.disabled) return;
    //   if (this.toggle) return;
    //   this.localPressed = false;
    // },

    onClick(): void {
      if (this.disabled) return;
      if (this.toggle) this.localPressed = !this.localPressed;
      this.$emit("click");
    },
  },
});
