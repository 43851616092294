



























































































































































































import Vue from 'vue';
import WindowButton from '@/components/ms_word/WindowButton.vue';
import EditorDropdown from '@/components/ms_word/EditorDropdown.vue';
import EditorButton from '@/components/ms_word/EditorButton.vue';
import WordIcon from '@/components/ms_word/WordIcon.vue';
import WindowMenu, { WindowMenuItem } from '@/components/ms_word/WindowMenu.vue';
import { range } from 'underscore';
import store from '@/store';

export default Vue.extend({
  components: {
    WindowButton,
    EditorDropdown,
    EditorButton,
    WordIcon,
    WindowMenu,
  },

  props: {
    fileName: {
      type: String,
      default: "Document1",
    },
  },

  data() {
    return {
      alignLeftPressed: true,
      alignCenterPressed: false,
      alignRightPressed: false,
      justifyPressed: false,
      fileMenuOpen: false,
      editMenuOpen: false,
      viewMenuOpen: false,
      insertMenuOpen: false,
      formatMenuOpen: false,
      toolsMenuOpen: false,
      tableMenuOpen: false,
      windowMenuOpen: false,
      helpMenuOpen: false,
    };
  },

  computed: {
    // TODO: extract; this component shouldn't know about the site as a whole
    windowMenuItems(): WindowMenuItem[] {
      const rsvpItem = { label: "RSVP", shortcut: "Alt+R", action: () => this.$router.push({ name: "Rsvp" }) };

      return [
        { label: "Home", shortcut: "Alt+H", action: () => this.$router.push({ name: "Home" }) },
        // { label: "FAQ", shortcut: "Alt+I", action: () => this.$router.push({ name: "Faq" }) },
        { label: "Hotels", shortcut: "Alt+O", action: () => this.$router.push({ name: "Hotels" }) },
        { label: "Us", shortcut: "Alt+U", action: () => this.$router.push({ name: "Us" }) },
        { label: "Map", shortcut: "Alt+M", action: () => this.$router.push({ name: "Map" }) },
        ...(store.getters.authorizedFor('rsvp') ? [rsvpItem] : []),
      ];
    },

    someMenuOpen(): boolean {
      return this.fileMenuOpen
        || this.editMenuOpen
        || this.viewMenuOpen
        || this.insertMenuOpen
        || this.formatMenuOpen
        || this.toolsMenuOpen
        || this.tableMenuOpen
        || this.windowMenuOpen
        || this.helpMenuOpen;
    },
  },

  watch: {
    alignLeftPressed(newVal: boolean, _oldVal: boolean): void {
      if (newVal) {
        this.alignCenterPressed = false;
        this.alignRightPressed = false;
        this.justifyPressed = false;
      }
    },

    alignCenterPressed(newVal: boolean, _oldVal: boolean): void {
      if (newVal) {
        this.alignLeftPressed = false;
        this.alignRightPressed = false;
        this.justifyPressed = false;
      } else if (!this.alignRightPressed && !this.justifyPressed) {
        this.alignLeftPressed = true;
      }
    },

    alignRightPressed(newVal: boolean, _oldVal: boolean): void {
      if (newVal) {
        this.alignLeftPressed = false;
        this.alignCenterPressed = false;
        this.justifyPressed = false;
      } else if (!this.alignCenterPressed && !this.justifyPressed) {
        this.alignLeftPressed = true;
      }
    },

    justifyPressed(newVal: boolean, _oldVal: boolean): void {
      if (newVal) {
        this.alignLeftPressed = false;
        this.alignCenterPressed = false;
        this.alignRightPressed = false;
      } else if (!this.alignCenterPressed && !this.alignRightPressed) {
        this.alignLeftPressed = true;
      }
    },

    fileMenuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal) this.closeAllMenus({ except: 'file' });
    },

    editMenuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal) this.closeAllMenus({ except: 'edit' });
    },

    viewMenuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal) this.closeAllMenus({ except: 'view' });
    },

    insertMenuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal) this.closeAllMenus({ except: 'insert' });
    },

    formatMenuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal) this.closeAllMenus({ except: 'format' });
    },

    toolsMenuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal) this.closeAllMenus({ except: 'tools' });
    },

    tableMenuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal) this.closeAllMenus({ except: 'table' });
    },

    windowMenuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal) this.closeAllMenus({ except: 'window' });
    },

    helpMenuOpen(newVal: boolean, _oldVal: boolean): void {
      if (newVal) this.closeAllMenus({ except: 'help' });
    },
  },

  mounted(): void {
    (this.$el as HTMLElement).focus();
  },

  beforeRouteUpdate(_to, _from, next): void {
    (this.$el as HTMLElement).focus();
    next();
  },

  methods: {
    // TODO: extract; this component shouldn't know about the site as a whole
    onKeydown(event: KeyboardEvent): void {
      if (event.altKey) {
        const shortcuts = {
          KeyH: "Home",
          KeyI: "Faq",
          KeyO: "Hotels",
          KeyU: "Us",
          KeyM: "Map",
          KeyR: "Rsvp",
        };
        const routeName = shortcuts[event.code as keyof typeof shortcuts];
        if (routeName) {
          event.preventDefault();
          this.$router.push({ name: routeName });
        }
      }
    },

    closeAllMenus({ except }: { except?: 'file' | 'edit' | 'view' | 'insert' | 'format' | 'tools' | 'table' | 'window' | 'help' } = {}): void {
      if (except !== 'file') this.fileMenuOpen = false;
      if (except !== 'edit') this.editMenuOpen = false;
      if (except !== 'view') this.viewMenuOpen = false;
      if (except !== 'insert') this.insertMenuOpen = false;
      if (except !== 'format') this.formatMenuOpen = false;
      if (except !== 'tools') this.toolsMenuOpen = false;
      if (except !== 'table') this.tableMenuOpen = false;
      if (except !== 'window') this.windowMenuOpen = false;
      if (except !== 'help') this.helpMenuOpen = false;
    },

    range(...args: Parameters<typeof range>): number[] {
      return range(...args);
    },

    // onClickPrint(): void {
    //   window.print();
    // },
  },
});
