













































































import Vue from 'vue';
import MicrosoftWord from '@/components/MicrosoftWord.vue';
import { noop, throttle } from 'underscore';

// adapted from https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
// TODO: refactor
async function * fetchHtmlLinesFromUrl(url: string): any {
  const utf8Decoder = new TextDecoder('utf-8');
  const response = await fetch(url);
  const reader = response.body!.getReader();
  let { value: chunk, done: readerDone } = await reader.read();
  let chunkStr = chunk ? utf8Decoder.decode(chunk) : '';

  const re = /\n|\r|\r\n/gm;
  let startIndex = 0;

  for (;;) {
    const result = re.exec(chunkStr);

    if (!result) {
      if (readerDone) break;

      const remainder = chunkStr.substr(startIndex);
      ({ value: chunk, done: readerDone } = await reader.read());
      chunkStr = remainder + (chunk ? utf8Decoder.decode(chunk) : '');
      startIndex = re.lastIndex = 0;
      continue;
    }

    yield chunkStr.substring(startIndex, result.index);
    startIndex = re.lastIndex;
  }

  if (startIndex < chunkStr.length) yield chunkStr.substr(startIndex);
}

// adapted from https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch
// TODO: refactor
async function getHtml(url: string): Promise<string> {
  let html = "";

  for await (const line of fetchHtmlLinesFromUrl(url)) {
    html = html + line;
  }

  return html;
}

let syncResize = noop;

export default Vue.extend({
  components: {
    MicrosoftWord,
  },

  data() {
    return {
      loaded: false,
      // loaded: true,
      faqFrameHeight: 0,
    };
  },

  computed: {
    loading(): boolean {
      return !this.loaded;
    },

    faqFrameStyle(): Partial<CSSStyleDeclaration> {
      return {
        height: `${this.faqFrameHeight}px`,
      };
    },
  },

  mounted(): void {
    // const frameWindow = (this.$refs.faqFrame as HTMLIFrameElement).contentWindow;
    // console.log("here it is:", frameWindow);
    // if (frameWindow) frameWindow.addEventListener("message", console.log);

    const docUrl = "https://docs.google.com/document/d/e/2PACX-1vScaBemyHy-rW3ZzyDK9mI-EHQ7lKmQvPKXEFBKgg4AChZFCkJfu1VvfRGvK7d3BF1FYTSmh2_I_atm/pub?embedded=true";
    getHtml(docUrl).then((html) => {
      const faqFrameDoc = (this.$refs.faqFrame as HTMLIFrameElement).contentDocument!;
      faqFrameDoc.write(html);
      faqFrameDoc.querySelectorAll("a").forEach((el) => {
        el.target = "_blank";
        el.rel = "noopener noreferrer";
      });
      this.loaded = true;
    }).then(() => {
      this.syncFaqFrameHeight();
    });

    syncResize = throttle(() => this.syncFaqFrameHeight(), 300);
    window.addEventListener("resize", syncResize);
  },

  beforeDestroy(): void {
    window.removeEventListener("resize", syncResize);
  },

  methods: {
    onCloseWordWindow(): void {
      this.$router.back();
    },

    onFaqFrameLoaded(): void {
      this.loaded = true;
    },

    syncFaqFrameHeight(): void {
      const faqFrameDoc = (this.$refs.faqFrame as HTMLIFrameElement).contentDocument;
      if (!faqFrameDoc) return;

      this.faqFrameHeight = faqFrameDoc.body.scrollHeight;
    },

    onPrint(): void {
      (this.$refs.faqFrame as HTMLIFrameElement).contentWindow!.print();
    },

    onSave(): void {
      (this.$refs.faqFrame as HTMLIFrameElement).contentWindow!.print();
    },
  },
});
