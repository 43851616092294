







import Vue from 'vue';

export default Vue.extend({
  props: {
    value: {
      type: [String, Number],
      default: "",
    },

    whitespace: {
      type: String,
      default: "1em",
    },
  },
});
