




















import { MsWordIconName } from '@/lib/ms_word/icons';
import WordIcon from '@/components/ms_word/WordIcon.vue';
import Vue, { PropType } from 'vue';

export default Vue.extend({
  components: {
    WordIcon,
  },

  props: {
    icon: {
      type: String as PropType<MsWordIconName>,
      required: true,
    },

    pressed: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      localPressed: this.pressed,
    };
  },

  watch: {
    localPressed(newVal: boolean, _oldVal: boolean): void {
      if (newVal !== this.pressed) this.$emit("update:pressed", newVal);
    },

    pressed(newVal: boolean, _oldVal: boolean): void {
      if (newVal !== this.localPressed) this.localPressed = newVal;
    },
  },

  // methods: {
  //   onMouseDown(): void {
  //     this.localPressed = true;
  //   },

  //   onMouseUp(): void {
  //     this.localPressed = false;
  //     this.$emit('click');
  //   },
  // },
});
